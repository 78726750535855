import React from 'react'

const Legal = () => {
  return (
    <div className='flex flex-col items-center w-full h-screen bg-white text-dark-purple'>
      <h1 className='text-primary text-5xl mt-10'>Mentions légales</h1>
        <p className='mt-10 w-1/2'>Le document suivant fourni des données obligatoires concernant l'éditeur du site, ces obligations en matière de protection des données, ainsi que d'autres références juridiques importantes concernant le site Internet de Konecton (https://www.konecton.com) tel que requis par la loi.</p>
      <div className='flex flex-col items-start w-1/2 mt-10'>
        <h2 className='font-bold text-lg'>Konecton</h2>
        <p>Konecton est un nom d'utilisation lié à la micro-entreprise géré par LANGOT SYLVAIN</p>
        <p>N° SIREN: 911593234</p>
        <p>Siège social : 61 BD SAINT-EXUPERY 13140 MIRAMAS FRANCE</p>
        <p>Date d'imatriculation: 21/03/2022</p>
        <p>Mail : langot.sylvain.contact@gmail.com</p>
        <p>Directeur de publication : Sylvain Langot</p>
      </div>

      <div className='flex flex-col items-start w-1/2 mt-10'>
        <h2 className='font-bold text-lg'>Hébergeur du site</h2>
        <p>o2switch</p>
        <p>Siret : 510 909 807 00032</p>
        <p>RCS Clermont Ferrand</p>
        <p>SAS au capital de 100 000€</p>
        <p>Opérateur Télécom déclaré ARCEP 09/2989 – AS50474</p>
      </div>

      <div className='flex flex-col items-start w-1/2 mt-10'>
        <h2 className='font-bold text-lg'>Images du site</h2>
        <p>Tous droits réservés par les propriétaires des images</p>
        <p><a href="https://wecheers.com">Wecheers</a></p>
        <p><a href="https://therapysansfrontieres.com">Therapy Sans Frontières</a></p>
        <p><a href="https://www.flaticon.com/free-animated-icons/" title="like animated icons">Icons created by Freepik - Flaticon</a></p>
        <p><a href="https://iconscout.com/lottie-animations/rocket" className="text-underline font-size-sm" target="_blank">Rocket</a> by <a href="https://iconscout.com/contributors/east-supply" className="text-underline font-size-sm" target="_blank">East Supply</a></p>
      </div>
    </div>
  )
}

export default Legal