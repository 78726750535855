import React, { useState } from 'react'

// Assets
import idea from '../assets/icons/idea.gif'
import laptop from '../assets/icons/laptop.gif'
import like from '../assets/icons/like.gif'
import logo from '../assets/konecton-simple-logo.png'
import responsive from '../assets/icons/responsive.gif'
import rocket from '../assets/icons/rocket.gif'
import shoppingbasket from '../assets/icons/shopping-basket.gif'
import smartphone from '../assets/icons/smartphone.gif'
import startup from '../assets/startup.svg'
import tsf from '../assets/partners/tsf.png'
import wecheers from '../assets/partners/wecheers.png'

// Components
import Separator from '../components/Separator'

// Icons
import { FaArrowRight, FaPaperPlane } from 'react-icons/fa6'

// Packages
import Swal from 'sweetalert2'

const Home = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768)
  const [emailInputValue, setEmailInputValue] = useState()
  const [nameInputValue, setNameInputValue] = useState()
  const [phoneInputValue, setPhoneInputValue] = useState()
  const [companyInputValue, setCompanyInputValue] = useState()
  const [messageInputValue, setMessageInputValue] = useState()
  const [checkConditions, setCheckConditions] = useState(false)

  const handleConditions = () => {
    Swal.fire({
      title: 'Conditions de confidentialité',
      text: 'En cochant cette case, vous acceptez que nous gardions uniquement pour nous vos cordonnées afin de vous recontacter.',
      icon: 'succes',
      confirmButtonText: 'Ok'
    })
  }

  const generateMailtoLink = () => {
    const mailtoLink = `mailto:langot.sylvain.contact@gmail.com?subject=Demande d'information
  &from=${emailInputValue}
  &from%20${nameInputValue}
  &body=Nom: ${nameInputValue}
Email: ${emailInputValue}
Téléphone: ${phoneInputValue}
Entreprise: ${companyInputValue}
  
${messageInputValue}`;
    return encodeURI(mailtoLink);
  };

  const handleSendEmail = (event) => {
    event.preventDefault();
    if(checkConditions) {
      const mailtoURL = generateMailtoLink();
      window.location.href = mailtoURL;
      confirmationMessage()
    }
  };

  const confirmationMessage = () => {
    Swal.fire({
      title: "CONFIRMER L\'ENVOI DE VOTRE DEMANDE",
      icon: "info",
      html: `
        Notre équipe vous recontactera dans les plus brefs délais
      `,
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: `
        C'est envoyé
      `,
      confirmButtonAriaLabel: "C'est envoyé",
      cancelButtonText: `
        Annuler
      `,
      cancelButtonAriaLabel: "Annuler"
    })
    .then((result) => {
      if (result.isConfirmed) {
        Swal.close()
        window.location.reload()
      } else {
        Swal.close()
      }
    })
  }

  return (
    <main className='flex flex-col w-screen items-center justify-center text-black text-2xl -mt-5 font-poppins bg-white'>

      {/* HERO SECTION */}
      <section className='hero relative flex flex-col md:flex-row justify-center items-center w-full h-screen md:h-[110dvh] pb-10 md:pb-0 md:-mt-44 lg:-mt-32'>
        <article className='z-10 flex flex-col w-full md:w-1/2 text-center md:text-left items-center md:items-start justify-evenly md:justify-between md:mr-1 md:-mt-10 px-5 py-10 h-3/4 md:h-1/2'>
          <h1 className='text-4xl md:text-5xl lg:text-7xl text-dark-purple font-bold'>Propulsez <strong className='text-primary'>votre projet</strong></h1>
          {isSmallScreen &&
          <aside className='w-1/2 object-fill my-7 md:my-3'>
            <img src={startup} alt='business' className='drop-shadow-xl'/>
          </aside>
  }
          <p className='text-base md:text-xl text-center md:text-left md:my-4 text-dark-purple'>
            Chez Konecton, nous transformons vos concepts en solutions digitales puissantes.<br />
            </p>
          <p className='text-base md:text-xl text-center md:text-left md:my-4 text-dark-purple'>
            Que ce soit pour un site web captivant ou une application mobile innovante, nous allions créativité et technologie pour vous propulser en ligne.
          </p>
          <a href='/#contact' className='flex items-center justify-evenly transition ease-in-out duration-150 bg-primary md:bg-dark-purple px-5 py-4 w-full md:w-3/4 lg:w-1/2 font-bold text-lg md:text-2xl rounded-full text-white mt-7 md:mt-0 hover:bg-primary hover:shadow-xl'>Lancez votre projet <FaArrowRight /></a>
        </article>
        {!isSmallScreen &&
          <aside className='w-1/3 object-fill md:mt-0'>
            <img src={startup} alt='business' className='drop-shadow-xl w-full'/>
          </aside>
        }
        <Separator fillColor={"rgb(59, 130, 246)"} decorationColor={"rgb(41, 27, 68)"} />
      </section>

      {/* ACTIVITIES SECTION */}
      <section id='activities' className='second-section relative flex justify-center min-h-screen md:min-h-[160dvh] lg:min-h-[110dvh] w-full bg-primary overflow-hidden'>

        <article className='relative z-40 md:z-30 flex flex-col text-center justify-evenly px-5 bg-primary mt-20 md:-mt-44' >
          <h2 className='text-3xl md:text-5xl text-white font-bold mb-10 md:mb-0'>Nos services</h2>
          <div className='z-10 flex flex-col md:flex-row flex-wrap w-full gap-5 md:mx-5 md:-mt-20 md:justify-evenly'>
            <div className='activities-card'>
              <img src={responsive} className='activities-card-image'  />
              <h3 className='activities-card-title'>Site vitrine</h3>
              <p className='activities-card-text'>Donnez à votre entreprise une présence en ligne élégante et professionnelle avec un site vitrine qui reflète votre identité.</p>
            </div>
            <div className='activities-card'>
              <img src={shoppingbasket} className='activities-card-image' />
              <h3 className='activities-card-title'>Site e-commerce</h3>
              <p className='activities-card-text'>Développez votre boutique en ligne avec une plateforme e-commerce performante, conçue pour maximiser vos ventes.</p>
            </div>
            <div className='activities-card'>
              <img src={smartphone} className='activities-card-image' />
              <h3 className='activities-card-title'>Application mobile</h3>
              <p className='activities-card-text'>Transformez vos idées en applications mobiles intuitives et engageantes, prêtes à captiver vos utilisateurs.</p>
            </div>
            <div className='activities-card'>
              <img src={laptop} className='activities-card-image' />
              <h3 className='activities-card-title'>Optimisation du référencement</h3>
              <p className='activities-card-text'>Boostez votre visibilité en ligne grâce à un SEO optimisé, attirant ainsi plus de visiteurs qualifiés sur votre site.</p>
            </div>
            <div className='activities-card'>
              <img src={rocket} className='activities-card-image' />
              <h3 className='activities-card-title'>Refonte et amélioration de site</h3>
              <p className='activities-card-text'>Modernisez votre site web pour améliorer l'expérience utilisateur et renforcer votre image de marque.</p>
            </div>
            <div className='activities-card'>
              <img src={idea} className='activities-card-image' />
              <h3 className='activities-card-title'>Projet sur mesure</h3>
              <p className='activities-card-text'>Bénéficiez d'une solution digitale personnalisée, parfaitement adaptée à vos besoins et à vos objectifs spécifiques.</p>
            </div>
          </div>
        </article>

        <Separator fillColor={"rgb(255, 255, 255)"} decorationColor={"rgb(41, 27, 68)"} />
      </section>

      {/* PARTNERS SECTION */}
      <section id='partners' className='sub-section flex justify-center min-h-screen w-full bg-white'>
        <article className='z-10 flex flex-col justify-evenly items-center p-5 h-1/2 mt-20 md:-mt-6'>
          <h1 className='text-3xl md:text-5xl text-center text-black font-bold'>Ils nous font confiance</h1>
          <img src={like} className='w-1/6 md:w-[100px] my-7 md:my-16' />
          <div className='flex flex-col md:flex-row w-full justify-evenly gap-5'>
            <figure className='flex flex-col items-center my-5'>
              <img src={wecheers} className='w-1/3 md:w-1/3 rounded-2xl shadow-2xl transition-all duration-500 hover:scale-110' />
              <figcaption className='text-center text-sm mt-6'>Application <a href='https://apps.apple.com/fr/app/wecheers-lapp-des-soirées/id1616746725?l=en' className='text-primary font-bold' target='_blank'>iOS</a> et <a href='https://play.google.com/store/apps/details?id=com.polybris.wecheers' className='text-primary font-bold' target='_blank'>Android</a></figcaption>
            </figure>
            <figure className='flex flex-col items-center my-5'>
              <img src={tsf} className='w-1/3 md:w-1/3 rounded-2xl shadow-2xl transition-all duration-500 hover:scale-110 bg-white' />
              <figcaption className='text-center text-sm mt-6'>Site internet</figcaption>
            </figure>
          </div>
        </article>

        <Separator fillColor={"rgb(59, 130, 246)"} decorationColor={"rgb(41, 27, 68)"} />
      </section>

       {/* CONTACT SECTION */}
       <section id='contact' className='relative flex justify-center items-center min-h-screen w-full bg-blue-500'>
        <article className='z-10 flex flex-col justify-evenly p-5 h-screen w-full mt-20 md:-mt-0'>
          <h1 className='text-3xl md:text-5xl text-center text-white font-bold mb-5 md:mb-10'>Contactez-nous</h1>
          
          <form className='flex flex-col w-full h-full md:h-2/3 md:w-2/3 md:gap-5 mx-auto text-base md:text-lg' onSubmit={(e) => handleSendEmail(e)}>
            <div className='flex flex-col md:flex-row w-full items-center'>
              <input id='name' placeholder='Votre nom*' required className='px-3 py-2 w-5/6 mx-2 my-2 rounded-lg' onChange={(e) => setNameInputValue(e.target.value)} />
              <input id='company' placeholder='Votre entreprise' required className='px-3 py-2 w-5/6 mx-2 my-2 rounded-lg' onChange={(e) => setCompanyInputValue(e.target.value)} />
            </div>

            <div className='flex flex-col md:flex-row mb-2 w-full items-center'>
              <input type='tel' id='phone' placeholder='Votre téléphone*' required className='px-3 py-2 w-5/6 mx-2 my-2 rounded-lg' onChange={(e) => setPhoneInputValue(e.target.value)} />
              <input type='mail' id='mail' placeholder='Votre email*' required className='px-3 py-2 w-5/6 mx-2 my-2 rounded-lg' onChange={(e) => setEmailInputValue(e.target.value)} />
            </div>

            <textarea id='message' placeholder='Parlez nous de votre projet*' rows={5} required className='px-3 py-2 w-5/6 md:w-full mx-auto md:mx-2 rounded-lg' onChange={(e) => setMessageInputValue(e.target.value)}></textarea>

            <div className='flex flex-row items-center justify-center md:justify-start md:ml-2 mt-3 md:mt-0'>
              <input id='conditions' className='cursor-pointer scale-150 md:scale-125 mr-2' style={{accentColor : '#653C87'}} type='checkbox' required onChange={() => setCheckConditions(!checkConditions)} />
              <label onClick={() => handleConditions()} className='text-sm md:text-base text-white ml-2 underline underline-offset-4 cursor-pointer'>J'accepte les conditions de confidentialité</label>
            </div>

            <button type='submit' className='flex items-center justify-center gap-3 mt-10 px-5 py-2 md:w-1/4 rounded-lg text-white bg-dark-purple mx-auto transition-all duration-200 hover:bg-white hover:text-primary'>Envoyer <FaPaperPlane /></button>
          </form>
        </article>
      </section>
      <img src={logo} alt='Konecton' className='w-[50px]' />
    </main>
  )
}

export default Home