import React from 'react'

const Separator = ({fillColor, decorationColor, zIndex}) => {
  return (
    <div className='separator'>
    <svg className="separator-svg" width="100%" height="300" viewBox="0 0 100 100" preserveAspectRatio="none" fill={fillColor} version="1.1" xmlns="http://www.w3.org/2000/svg">
      <path d="M 100 100 V 10 L 0 100"/>
      <path d="M 30 73 L 100 18 V 10 Z" fill={decorationColor} strokeWidth="0"/>
      </svg>
  </div>
  )
}

export default Separator