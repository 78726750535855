// React
import { useState } from "react"

// Assets
import logo from '../assets/konecton_logo.png'

// Icons
import { FaBars, FaRegCircleXmark } from "react-icons/fa6"


const Nav = () => {
  const [toggleDropdown, setToggleDropdown] = useState(false)
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768)

  return (
    <header>
    <nav className="font-poppins bg-white text-primary relative z-50 flex flex-1 flex-between items-center w-screen py-2 px-5 mb-0">

      {/* Desktop Nav */}
      <div className="sm:flex flex-1 items-center justify-center hidden">
        {!isSmallScreen &&
          <a href="/" className="flex flex-1 justify-center md:justify-start text-3xl md:text-5xl">
            <img src={logo} className="w-1/4"/>
          </a>
        }
        <div className="flex flex-1 justify-evenly items-center">
          <a href="/#activities" className="transition ease-in-out duration-150 rounded-full bg-white px-4 py-2 text-primary font-bold hover:underline underline-offset-8 hover:text-dark-purple">
            Nos services
          </a>
          <a href="/#partners" className="transition ease-in-out duration-150 rounded-full bg-white px-4 py-2 text-primary font-bold hover:underline underline-offset-8 hover:text-dark-purple">
            Nos partenaires
          </a>
          <a href="/#contact" className="transition ease-in-out duration-150 rounded-full bg-primary px-4 py-2 text-white font-bold hover:bg-dark-purple">
            Devis gratuit
          </a>
        </div>
      </div>

      {/* Mobile Nav */}
      <div className="sm:hidden flex w-full justify-end right-5 top-3">
        {toggleDropdown ? (
          <>
            <div className="overlay" />
              <div className='dropdown'>
              <button className='dropdown_close' onClick={() => setToggleDropdown(false)}><FaRegCircleXmark /></button>
              <a href='/#activities'
                className='dropdown_link'
                onClick={() => setToggleDropdown(false)}>
                Nos services
              </a>
              <a href='/#partners'
                className='dropdown_link'
                onClick={() => setToggleDropdown(false)}>
                Nos partenaires
              </a>
              <a href='/#contact'
                className='dropdown_link'
                onClick={() => setToggleDropdown(false)}>
                Devis gratuit
              </a>
              <div className="absolute bottom-2 w-full justify-center">
                <img src={logo} className="w-1/2 mx-auto"/>
              </div>
            </div>
          </>
          )
          :
          <div className="relative flex w-full items-center justify-center bg-white">
            <span href="/" className="flex flex-1 justify-center md:justify-start">
                <img src={logo} className="w-1/2"/>
            </span>
            <FaBars size={25} onClick={() => setToggleDropdown(true)} />
          </div>
        }
      </div>
    </nav>
    </header>
  )
}

export default Nav