import React from 'react'

const Footer = () => {
  return (
    <footer className='flex flex-col w-full items-center px-10 py-3 bg-dark-purple'>
      <p>Copyright 2024 Konecton - Langot Sylvain - Tous droits réservés - <a href="/legal">Mentions légales</a></p>
      <div className='flex flex-wrap justify-center gap-5 mt-4 md:mt-0'>
      <p className='underline text-center'>Crédits images :</p>
      <p className='md:border-r-2 border-white md:pr-3 text-center'><a href="https://www.flaticon.com/free-animated-icons/like" title="like animated icons">Like animated icons created by Freepik - Flaticon</a></p>
      <p className=''><a href="https://iconscout.com/lottie-animations/rocket" className="text-underline font-size-sm" target="_blank">Rocket</a> by <a href="https://iconscout.com/contributors/east-supply" className="text-underline font-size-sm" target="_blank">East Supply</a></p>
      </div>
    </footer>
  )
}

export default Footer